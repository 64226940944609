import React from "react";

// Components
import { Global } from "@emotion/core";
import Layout from "../components/Layouts/Layout";
import SEO from "../components/Utilities/SEO";
import Contact from "../components/Sections/SectionContact";

export default () => {
  return (
    <Layout>
      <SEO
        title="Get In Touch | Anana Vestige"
        description="Come discover a lifestyle to KNOW how much value you possess, to BELIEVE in the truth of who you are, and to FULFILL the great task you have been given."
      />
      <Global
        styles={{
          ".nav-item-contact": {
            color: "white"
          }
        }}
      />
      <Contact />
    </Layout>
  );
};
